.swiper-pagination {
  position: relative !important;
  top: auto !important;
  margin-top: 15px !important;
}
.swiper-pagination-bullet {
  display: inline-flex !important;
  width: 8px !important;
  height: 8px !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 50%;
  margin: 0 4px;
}
.swiper-pagination-bullet:hover {
  cursor: pointer !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff !important;
}
