.vibrate {
  position: relative;
}
.vibrate::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  /* background-color: var(--orange-color); */
  transform: scale(1);
  /* box-shadow: 0 0 0 rgba(0, 0, 0, 1); */
  animation: anim-vibrate 2s infinite;
}
@keyframes anim-vibrate {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--bg);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.pulse-border {
  position: relative;
  display: inline-block;
}

.pulse-border::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 2px solid var(--bg);
  border-radius: var(--radius);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 var(--bg);

    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.3;
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
    top: -10px;
    bottom: -10px;
  }
  100% {
    transform: scale(1.05);
    opacity: 0;
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
    top: -10px;
    bottom: -10px;
  }
}

@media screen and (max-width: 500px) {
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 var(--bg);

      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.3;
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
      top: -5px;
      bottom: -5px;
    }
    100% {
      transform: scale(1.05);
      opacity: 0;
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
    }
  }
}
