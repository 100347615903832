.container {
  max-width: 1295px !important;
}
.text-underlined {
  text-decoration: underline;
}

/* WidgetsIntercom */
button.iubenda-tp-btn.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"] {
  bottom: 45px !important;
  border-radius: 50% !important;
}
