@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?s8dbf3");
  src:
    url("fonts/icomoon.eot?s8dbf3#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?s8dbf3") format("truetype"),
    url("fonts/icomoon.woff?s8dbf3") format("woff"),
    url("fonts/icomoon.svg?s8dbf3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circle_play:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e913";
}
.icon-user_login:before {
  content: "\e912";
}
.icon-arrowUpRightFromSquare:before {
  content: "\e911";
}
.icon-arrowR:before {
  content: "\e910";
}
.icon-arrowDown:before {
  content: "\e900";
}
.icon-location_f:before {
  content: "\e903";
}
.icon-phone_f:before {
  content: "\e904";
}
.icon-fb:before {
  content: "\e905";
}
.icon-insta:before {
  content: "\e906";
}
.icon-twitt:before {
  content: "\e907";
}
.icon-xmark:before {
  content: "\e908";
  color: #94a3b8;
}
.icon-linkid:before {
  content: "\e909";
}
