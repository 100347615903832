/* Animation Lines */
@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 822.553955078125px;
    stroke-dasharray: 822.553955078125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 822.553955078125px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 822.553955078125px;
    stroke-dasharray: 822.553955078125px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 822.553955078125px;
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-stroke-1 6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
  animation: animate-svg-stroke-1 6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
}

@-webkit-keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 346.3005065917969px;
    stroke-dasharray: 346.3005065917969px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 346.3005065917969px;
  }
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 346.3005065917969px;
    stroke-dasharray: 346.3005065917969px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 346.3005065917969px;
  }
}

.svg-elem-2 {
  -webkit-animation: animate-svg-stroke-2 5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
  animation: animate-svg-stroke-2 5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
}

/* FlipCards */
.card {
  perspective: 800px;
}
.card__content {
  transform-style: preserve-3d;
}
.card__front,
.card__back {
  backface-visibility: hidden;
}
.card__back {
  transform: rotateY(0.5turn);
}
@media screen and (max-width: 768px) {
  .card.flip .card__content {
    transform: rotateY(0.5turn);
  }
}
@media (min-width: 768px) {
  .card:hover .card__content {
    transform: rotateY(0.5turn);
  }
}

/* Typing Text */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.typing-text {
  overflow: hidden;
  border-right: 0.15em solid black;
  white-space: nowrap;
  animation:
    typing 2s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

/* FadeIn Text */
@keyframes fadeIn {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 0%;
  }
}
@-webkit-keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

@keyframes rainbow_animation {
  from {
    background-position: 100% 0%;
  }
  to {
    background-position: 0% 0%;
  }
}

.fade-in-text {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#11374d),
    color-stop(#333a48),
    color-stop(#333a48),
    color-stop(#e8f4fa),
    to(#e8f4fa)
  );
  background: -o-linear-gradient(left, #11374d, #333a48, #333a48, #e8f4fa, #e8f4fa);
  background: linear-gradient(to right, #11374d, #333a48, #333a48, #e8f4fa, #e8f4fa);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 400% 100%;
  color: transparent;
  -webkit-animation: rainbow_animation 300ms ease-in-out forwards;
  animation: rainbow_animation 300ms ease-in-out forwards;
}
